import React, { useState } from 'react';
import { Flex, Box, Button, Text, Card } from 'rebass';
import { navigate } from 'gatsby';
import { FaSearch } from 'react-icons/fa';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Categories from '../components/Categories';

const imgBackground = require('../images/search_1.jpg');

const ExplorePage = () => {
  const [query, setQuery] = useState('');
  return (
    <Layout headerRight="restaurants">
      <SEO
        pageTitle="Esplora i piatti nelle tue vicinanze - Mangiami"
        pageDescription="Scegli cosa e dove mangiare esplorando le categorie per tipologia di cucina o utilizza il motore di ricerca integrato per trovare il piatto che desideri."
        pageImage="https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/0000%20static/mangiami%20thumbnail%20-%20final.png"
        pageUrl="/esplora"
      />
      <Card
        css={`
          height: 200px;
          background-image: url('${imgBackground}');
          background-size: cover;
          background-position: center;
          @media only screen and (min-width: 576px) {
            height: 350px;
          }
        `}
        width={1}
        py={[2, 3]}
      >
        <Flex
          mx="auto"
          py={[2, 4]}
          style={{
            maxWidth: 1200,
          }}
        >
          <Box width={1} py={[4, 5]} px={[2, 3]} flexDirection="column">
            <Text
              css={`
                text-shadow: 2px 1px 4px #111;
                @media only screen and (min-width: 576px) {
                  text-shadow: 2px 1px 10px #333;
                }
              `}
              mb={[4, 5]}
              textAlign="center"
              color="#fff"
              fontSize={[4, 6]}
            >
              Cosa vuoi mangiare oggi?
            </Text>
            <Flex
              mx={[0, -1, -2]}
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                style={{ position: 'relative' }}
                width={[1, 500, 600]}
                px={[1, 2]}
              >
                <input
                  placeholder="spaghetti alla carbonara"
                  value={query}
                  className="big-search-bar"
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      navigate(
                        `/search/?query=${encodeURIComponent(e.target.value)}`,
                      );
                    }
                  }}
                  onChange={e => setQuery(e.target.value)}
                />
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    bottom: 0,
                  }}
                >
                  <Button
                    onClick={() =>
                      navigate(`/search/?query=${encodeURIComponent(query)}`)
                    }
                    width={80}
                    variant="primary"
                    style={{
                      height: '100%',
                      marginTop: 0,
                      borderTopRightRadius: 8,
                      borderBottomRightRadius: 8,
                    }}
                  >
                    <FaSearch size={20} style={{ marginTop: 0 }} />
                  </Button>
                </div>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Card>

      {/* categorie */}
      <div style={{ maxWidth: 1200, margin: '0 auto', paddingBottom: 40 }}>
        <Categories />
      </div>
    </Layout>
  );
};

export default ExplorePage;
